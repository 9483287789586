import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useBase } from '../contexts/BaseContext';
const CheckoutForm = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const {form,amount,setPurchaseComplete,checkForm,price,color} = useBase();
    
    const handleSubmit = async (event) => {
    event.preventDefault();
    if (checkForm()) {
      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createSource(cardElement);
      if (result.error) {
        console.error(result.error.message);
      } else {
        const sourceId = result.source.id;
        const chargeDetails = {
          source: sourceId,
          amount: price * 100,
          reservationName: form.name,
          cname: form.company,
          watnum: form.taxNumber,
          postalCode: form.postalCode,
          city: form.city,
          adress: form.address,
          country: form.country,
          email: form.email,
          phone: form.phone,
          comment: form.other,
          descr: amount + " - " + color,
        };
        axios.post('https://addictedshop.hu:41807/api/payments/charge', chargeDetails, {
          headers: {
            Authorization: `Bearer ${"AddictedAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
          .then(response => {
            console.log('Payment Success:', response.data);
            const { docid, status } = response.data;  // Extracting docid and status from the response
            setPurchaseComplete(true);
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
        });
      }
    } else {
        console.log("Form error");
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit({ preventDefault: () => {} });
    }
  }));

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: '24px', padding: '10px' } } }}/>
    </form>
  );
});

export default CheckoutForm;