import React, { useEffect, useState } from 'react';
import { useAdmin } from './AdminContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminKeszlet = () => {
    const { state, setState, getKeszlet, setKeszlet } = useAdmin();
    const [show, setShow] = useState(false);

    useEffect(() => {
        getKeszlet(1);
        getKeszlet(2);
        getKeszlet(3);
        setTimeout(() => {
            setShow(true);
        }, 500);
    }, []);

    const handleCheckboxChange = (index, field) => {
        const newState = [...state];
        newState[index][field] = !newState[index][field];
        setState(newState);
    };

    const handleColorChange = (index, color) => {
        const newState = [...state];
        newState[index].color = color;
        setState(newState);
    };

    const handleNumberChange = (index, number) => {
        const newState = [...state];
        newState[index].number = number;
        setState(newState);
    };

    const handlePriceChange = (index, price) => {
        const newState = [...state];
        newState[index].price = price;
        setState(newState);
    };

    const set = (id) => {
        setKeszlet(id)
            .then(() => {
                console.log('Beállítás sikeres!');
                toast.success('Beállítás sikeres!');
            })
            .catch(() => {
                console.log('Beállítás sikertelen!');
                toast.error('Beállítás sikertelen!');
            });
    };

    return (
        <>
            {show && (
                <div className="keszlet-container">
                    {state.map((item, index) => (
                        <div key={index} className="keszlet-section">
                            <div className="checkbox-container">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={item.active}
                                        onChange={() => handleCheckboxChange(index, 'active')}
                                    />
                                    Active
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={item.soldOut}
                                        onChange={() => handleCheckboxChange(index, 'soldOut')}
                                    />
                                    Sold Out
                                </label>
                            </div>
                            <div className="color-input-container">
                                <input
                                    type="color"
                                    value={item.color}
                                    onChange={(e) => handleColorChange(index, e.target.value)}
                                />
                                <div
                                    className="color-display"
                                    style={{ backgroundColor: item.color }}
                                ></div>
                            </div>
                            <div className="number-input-container">
                                <input
                                    type="number"
                                    value={item.number}
                                    min="0"
                                    max="1000000"
                                    onChange={(e) => handleNumberChange(index, parseInt(e.target.value, 10))}
                                />
                            </div>
                            <div className="number-input-container">
                                <input
                                    type="number"
                                    value={item.price}
                                    min="0"
                                    max="999999999"
                                    onChange={(e) => handlePriceChange(index, parseInt(e.target.value, 10))}
                                />
                            </div>
                            <button onClick={() => set(index + 1)} style={{ color: 'black' }}>Beállít</button>
                        </div>
                    ))}
                </div>
            )}
            <ToastContainer position="bottom-left"/>
        </>
    );
};

export default AdminKeszlet;