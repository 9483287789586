import React, { forwardRef, useImperativeHandle,useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutFrom';

const stripePromise = loadStripe('pk_live_51Pb3CFKkVdDWs7XZao7dpnu1KfYFowLbiM8FR8hdtAHegQoM7F1oTbi0PefVntnFqu4k4Q2qXbmopE16Yf0nmo8e00oUiFlo37');


const StripePayment = forwardRef((props, ref) => {
return (
  <Elements stripe={stripePromise} options={{ locale: 'hu' }}>
    <div className="payment-container">
      <CheckoutForm ref={ref} />
    </div>
  </Elements>
)   
});

export default StripePayment;