import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

const BaseContext = createContext(null);

export const useBase = () => useContext(BaseContext);

export const BaseProvider = ({ children }) => {
    const [purchaseComplete, setPurchaseComplete] = useState(false); // New state to track purchase completion
    const [color, setColor] = useState('red');
    const [pState,setPState] = useState(1);
    const [popOn,setPopOn] = useState(false);
    const openPopUp = () => {setPopOn(true);}
    const closePopUp = () => {setPopOn(false);setPurchaseComplete(false);}
    const initialBorderStyle = "2px solid #2e2a27";
    const [bstlye, setBstyle] = useState(initialBorderStyle);
    const [bstlye2, setBstyle2] = useState(initialBorderStyle);
    const [bstlye3, setBstyle3] = useState(initialBorderStyle);
    const [bstlye4, setBstyle4] = useState(initialBorderStyle);
    const [bstlye5, setBstyle5] = useState(initialBorderStyle);
    const [bstlye6, setBstyle6] = useState(initialBorderStyle);
    const [bstlye7, setBstyle7] = useState(initialBorderStyle);
    const [erM,setErM] = useState("");
  
    const changeBorderStyle = (number) => {
        console.log("changeBorder - "+number)
      switch (number) {
        case 1:
          setBstyle("2px solid red");
          setTimeout(() => setBstyle(initialBorderStyle), 3000);
          break;
        case 2:
          setBstyle2("2px solid red");
          setTimeout(() => setBstyle2(initialBorderStyle), 3000);
          break;
        case 3:
          setBstyle3("2px solid red");
          setTimeout(() => setBstyle3(initialBorderStyle), 3000);
          break;
        case 4:
          setBstyle4("2px solid red");
          setTimeout(() => setBstyle4(initialBorderStyle), 3000);
          break;
        case 5:
          setBstyle5("2px solid red");
          setTimeout(() => setBstyle5(initialBorderStyle), 3000);
          break;
        case 6:
          setBstyle6("2px solid red");
          setTimeout(() => setBstyle6(initialBorderStyle), 3000);
          break;
        case 7:
          setBstyle7("2px solid red");
          setTimeout(() => setBstyle7(initialBorderStyle), 3000);
          break;
        default:
          break;
      }
    };
    const checkForm = () => {
        let b = true;
        if (form.name === "") {changeBorderStyle(1);b = false;}
        if (form.phone === "") {changeBorderStyle(2);b = false;}
        if (form.email === "") {changeBorderStyle(3);b = false;}
        if (form.country === "") {changeBorderStyle(4);b = false;}
        if (form.postalCode === "") {changeBorderStyle(5);b = false;}
        if (form.city === "") {changeBorderStyle(6);b = false;}
        if (form.address === "") {changeBorderStyle(7);b = false;}
        return b;
    }
    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: '',
        taxNumber: '',
        company: '',
        country: '',
        postalCode: '',
        city: '',
        address: '',
        other: ''
      });
    const [amount,setAmount] = useState(1);
    const [maxamount,setMaxAmount] = useState(10);
    const [price,setPrice] = useState(7990);
    const addAmount = () => {if (maxamount > amount) {setAmount(amount+1);setPrice((amount+1)*7990);}}
    const remAmount = () => {if (amount > 1) {setAmount(amount-1);setPrice((amount-1)*7990);}}

    // Context value that will be passed to consumers
    const contextValue = {
        popOn,setPopOn,closePopUp,openPopUp,
        form, setForm,price,setPrice,
        amount,addAmount,remAmount,purchaseComplete, setPurchaseComplete,pState,setPState,maxamount,setMaxAmount,setAmount,color, setColor,
        bstlye,setBstyle,bstlye2,setBstyle2,bstlye3,setBstyle3,bstlye4,setBstyle4,bstlye5,setBstyle5,bstlye6,setBstyle6,bstlye7,setBstyle7,changeBorderStyle,checkForm
    };

    return <BaseContext.Provider value={contextValue}>{children}</BaseContext.Provider>;
};