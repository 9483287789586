import React, { useEffect } from "react";
import "../mainpagePC/MainPagePCstyle.css";
import { Helmet } from "react-helmet";
import { useBase } from "../../contexts/BaseContext";
import NavHeaderPhone from "./components/NavHeaderPhone";
import HeroPc from "../mainpagePC/components/HeroPc";
import TermekPc from "../mainpagePC/components/TermekPc";
import RolunkPC from "../mainpagePC/components/RolunkPC";
import FooterPC from "../mainpagePC/components/FooterPc";
import License from "../mainpagePC/components/LicensePc";
import Popup from "../../components/BuyPopup";
import { useAdmin } from "../../admin/AdminContext";

const MainPagePhone = () => {
  const {popOn} = useBase();
  const {getKeszlet} = useAdmin();
  useEffect(() => {
    document.body.style.overflowY = popOn ? 'hidden' : 'scroll';
    return () => {
      document.body.style.overflowY = 'scroll'; // Cleanup on unmount
    };
  }, [popOn]);
  useEffect(() => {
    getKeszlet(1);
    getKeszlet(2);
    getKeszlet(3);
}, []);
  return (<>
    <Helmet>
      <title>Addicted</title>
      <meta name="description" content="Addicted" />
    </Helmet>
    <div className="Main-Page-PC" >
        <HeroPc/>
        <NavHeaderPhone/>
        <RolunkPC/>
        <TermekPc/>
        <FooterPC/>
        <License/>
        {popOn &&<Popup/>}
    </div>
    </>
  );
};

export default MainPagePhone;