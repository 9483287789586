import React, { useEffect, useState } from "react";
import "../MainPagePCstyle.css";

const NavHeaderPC = () => {
  const [scale, setScale] = useState(3);
  const [position, setPosition] = useState(60);
  const [style, setStyle] = useState({
    width: '35em',
    height: '35em',
    borderRadius: '35em'
  });
  const [style1, setStyle1] = useState({ opacity: 1, transform: 'translateY(0px)' });
  const [style2, setStyle2] = useState({ opacity: 1, transform: 'translateY(0px)' });
  const [style3, setStyle3] = useState({ opacity: 1, transform: 'translateY(0px)' });
  const [style4, setStyle4] = useState({ opacity: 1, transform: 'translateY(0px)' });

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const heroHeight = windowHeight;
      let newScale = 3;
      let newPosition = 50;
      newPosition = 50 - (scrollY / 450) * 50;
      newScale = Math.max(1, 3 - (scrollY / 450));
      console.log(scrollY )
      newPosition+=10;
      setScale(newScale);
      setPosition(newPosition);

      if (scrollY >= 1800 && scrollY <= 3500) {
        const progress = (scrollY - 1800) / (3500 - 1800); // Calculate the progress between 0 and 1
        const newWidth = 35 + (95 - 35) * progress;
        const newHeight = 35 + (40 - 35) * progress;
        const newBorderRadius = 35 + (3 - 35) * progress;

        setStyle({
          width: `${newWidth}em`,
          height: `${newHeight}em`,
          borderRadius: `${newBorderRadius}em`
        });
      } else if (scrollY < 1800) {
        setStyle({
          width: '35em',
          height: '35em',
          borderRadius: '35em'
        });
      } else if (scrollY > 3500) {
        setStyle({
          width: '95em',
          height: '40em',
          borderRadius: '3em'
        });
      }

      const getStyle = (start, end) => {
        if (scrollY < start) {
          return { opacity: 1, transform: 'translateY(0px)' };
        } else if (scrollY > end) {
          return { opacity: 0, transform: 'translateY(100px)' };
        } else {
          const progress = (scrollY - start) / (end - start);
          const opacity = 1 - progress/2 ;
          const translateY = progress * 100;
          return { opacity, transform: `translateY(${translateY}px)` };
        }
      };

      setStyle1(getStyle(700, 850));
      setStyle2(getStyle(850, 1000));
      setStyle3(getStyle(1000, 1150));
      setStyle4(getStyle(1150, 1300));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToPosition = () => {
    window.scrollTo({
      top: 4750,
      behavior: 'smooth'
    });
  };

  const scrollToPosition2 = () => {
    window.scrollTo({
      top: 6100,
      behavior: 'smooth'
    });
  };
  const scrollToTermekeinkhead = () => {
    const element = document.getElementById('termekeinkhead');
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  const scrollToMarqueecontainer = () => {
    const element = document.getElementById('marqueecontainer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToPosition3 = () => {
    window.scrollTo({
      top: 10000,
      behavior: 'smooth'
    });
  };

  return (
    <div className="wrapper">
      <section className="section is--nav">
        <div className="container is--nav">
          <div className="nav_top">
            <div
              className="nav_logo-contain"
              style={{
                position: 'absolute',
                top: `-${position}vh`,
                width: '100vw',
                transition: 'transform 1s, top 1s',
                maxWidth: '100vw',
                marginLeft: "-3.3%"
              }}
            >
              <a href="#" className="nav_logo w-inline-block">
                <div className="nav_logo-img w-embed">
                  <img
                    src="images/tomilogo2.png"
                    loading="lazy"
                    sizes="100vw"
                    srcSet="images/tomilogo2-p-500.png 500w, images/tomilogo2.png 800w"
                    alt=""
                    className="tomilogo"
                    style={{transform: `scale(${scale})`,msTransfrom :`scale(${scale})`,MozTransform:`scale(${scale})`,WebkitTransform:`scale(${scale})`,transition: 'transform 0.8s, top 0.8s'}}
                  />
                </div>
              </a>
            </div>
            <div className="nav_side">
              <a className="nav_link w-inline-block">
                <p className="nav_link-text" onClick={()=>scrollToMarqueecontainer()}>RÓLUNK</p>
              </a>
              <a className="nav_link w-inline-block">
                <p className="nav_link-text" onClick={()=>scrollToTermekeinkhead()}>TERMÉKEK</p>
              </a>
              <a className="nav_link w-inline-block">
                <p className="nav_link-text" onClick={()=>scrollToPosition3()}>KAPCSOLAT</p>
              </a>
            </div>
            <div className="nav_side is--right">
              <p className="nav_link-text">(2024), GMA CORPORATION. minden jog fenntartva</p>
            </div>
          </div>
        </div>
      </section>
      <div className="section is--header">
        <div className="container is--header">
        <div className="header_text">
          <div className="header_text-wrap" >
            <div className="header_text-move">
              <h1 className="heading-2" style={style1}>Az</h1>
            </div>
          </div>
          <div className="header_text-wrap" >
            <div className="header_text-move">
              <h1 className="is--alt-text" style={style2}>Addicted</h1>
            </div>
          </div>
          <div className="header_text-wrap" >
            <div className="header_text-move">
              <h1 className="heading" style={style3}>Grain</h1>
            </div>
          </div>
          <div className="header_text-wrap" >
            <div className="header_text-move is--last">
              <h1 className="heading-3" style={style4}>Guard</h1>
            </div>
          </div>
        </div>
          <div className="sticky-circle_wrap">
            <div className="sticky-circle">
              <div className="sticky-circle_element" style={style}>
                <img
                  src="images/IMG_4043.JPG"
                  loading="lazy"
                  sizes="100vw"
                  srcSet="images/IMG_4043-p-500.jpg 500w, images/IMG_4043.JPG 800w"
                  alt=""
                  className="sticky-circle_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeaderPC;