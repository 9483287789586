import React, { useRef, useState } from 'react';
import './BuyPopup.css'; // Assuming you have a Popup.css file for the styles
import StripePayment from './StripePayment';
import { useBase } from '../contexts/BaseContext';
import MessagePop from './MessagePop';
import { useAdmin } from '../admin/AdminContext';

const Popup = ({ maxAmount, onClose }) => {
  const {state} = useAdmin();
  const {
    popOn, setPopOn, closePopUp, openPopUp,
    form, setForm,
    amount,setAmount, addAmount, remAmount,purchaseComplete, setPurchaseComplete,
    bstlye,setBstyle,bstlye2,setBstyle2,bstlye3,setBstyle3,bstlye4,setBstyle4,bstlye5,setBstyle5,bstlye6,setBstyle6,bstlye7,setBstyle7,price,setPrice,
    maxamount,setMaxAmount,color, setColor
  } = useBase();

  
  const stripeRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    stripeRef.current.submit();
  };
  const doColor = (c) => {
    if (c === "red") {
      setMaxAmount(state[0].number);
      setAmount(1);
      setPrice(state[0].price);
      setColor("red");
    } else if (c === "green") {
      setMaxAmount(state[1].number);
      setAmount(1);
      setPrice(state[1].price);
      setColor("green");
    } else if (c === "blue") {
      setMaxAmount(state[2].number);
      setAmount(1);
      setPrice(state[2].price);
      setColor("blue");
    }
  }

  return (
    <div className="popup-overlay">
      <button className="close-button" onClick={() => closePopUp()}>X</button>
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-left">
                <div className="popup-left-top">
                  {color === 'red' && <img src="images/fesured.png" loading="lazy" alt="" className="popup-image" />}
                  {color === 'green' && <img src="images/fesuwhite.png" loading="lazy" alt="" className="popup-image" />}
                  {color === 'blue' && <img src="images/fesublue.png" loading="lazy" alt="" className="popup-image" />}
                  { !purchaseComplete && <div className="popup-left-top2">
                    <div className="color-selector">
                      {state[0].active && <label>
                        <input type="radio" name="color" value="red" onChange={() => doColor('red')} />
                        <span className="color-swatch red" style={{background:state[0].color}}></span>
                      </label>}
                      {state[1].active && <label>
                        <input type="radio" name="color" value="green" onChange={() => doColor('green')} />
                        <span className="color-swatch green" style={{background:state[1].color}}></span>
                      </label>}
                      {state[2].active && <label>
                        <input type="radio" name="color" value="blue" onChange={() => doColor('blue')} />
                        <span className="color-swatch blue" style={{background:state[2].color}}></span>
                      </label>}
                    </div>
                    <div className="amount-selector">
                      <label style={{ transform: "translateY(2px)" }}>Amount: </label>
                      {(color === "red" && !state[0].soldOut && state[0].number > 0) || (color === "green" && !state[1].soldOut && state[1].number > 0) || (color === "blue" && !state[2].soldOut && state[2].number > 0) ? <>
                        <div className='POPAMOUNTMIN' onClick={() => remAmount()} style={{ color: "black", marginLeft: "3px", lineHeight: "0", transform: "translateY(-1px)", cursor: "pointer" }}>-</div>
                      <div className='POPAMOUNT' style={{ color: "black", marginLeft: "3px" }}>{amount}</div>
                      <div className='POPAMOUNTADD' onClick={() => addAmount()} style={{ color: "black", marginLeft: "3px", lineHeight: "0", transform: "translateY(-1px)", cursor: "pointer" }}>+</div>
                      </>
                      :
                      <div style={{color:"red",fontWeight:"800"}}>Sold Out</div>
                      }
                    </div>
                    
                  </div>}
                </div>
                <div className='popup-left-scroll'>
                  <h3 className="heading-6">ADDICTED 2-4 mm toldófésű<br /></h3>
                  <div className="text-block-7">{price} Ft.</div>
                  <div className="text-block-7" style={{color:"red"}}>Lebomló műanyag!</div>
                  <div class="text-block-5">A termékeink 3D nyomtatással biológiai úton lebomló anyagból készülnek. Ez az anyag tökéletesen megfelel a toldófésűk anyagának, kissé rugalmas és rengeteg tesztelés után mondható, hogy a nagy mértékű rezgésnek is ellenáll. Viszont mivel lebomló anyagról beszélünk nem ajánlatos vegyszerben áztatni mivel egy idő után feloldódhat benne.</div>
              <div class="text-block-6">Kérünk titeket fokozottan figyeljetek erre és ha szükséges inkább csak fújjátok le fertőtlenítővel és töröljétek át. De a legcélszerűbb ha fújás után letörlitek a fertőtlenítő nagyját és hideg levegővel átfújhatjátok. Még egy fontos dolog amire fokozottan figyeljetek 55 °C-fok körül már lágyul az anyag így tartózkodjatok a magas hőtől való érintkezéstől.<br/></div>
                </div>
          </div>
          <div className="popup-right">
          {purchaseComplete ? ( 
            <MessagePop color={color}/>
            ) : (
            <div className="form-container">
                <form>
                <input type="text" name="name" placeholder="Név" value={form.name} onChange={handleChange} required style={{border: bstlye}}/>
                <input type="text" name="phone" placeholder="Telefon" value={form.phone} onChange={handleChange} required style={{border: bstlye2}}/>
                <input type="email" name="email" placeholder="Email" value={form.email} onChange={handleChange} required style={{border: bstlye3}}/>
                <input type="text" name="taxNumber" placeholder="Adószám (opcionális)" value={form.taxNumber} onChange={handleChange} />
                <input type="text" name="company" placeholder="Cégnév (opcionális)" value={form.company} onChange={handleChange} />
                <input type="text" name="country" placeholder="Ország" value={form.country} onChange={handleChange} required  style={{border: bstlye4}}/>
                <input type="text" name="postalCode" placeholder="Irányítószzám" value={form.postalCode} onChange={handleChange} required style={{border: bstlye5}}/>
                <input type="text" name="city" placeholder="Város" value={form.city} onChange={handleChange} required style={{border: bstlye6}}/>
                <input type="text" name="address" placeholder="Cím" value={form.address} onChange={handleChange} required style={{border: bstlye7}}/>
                <input type="text" name="other" placeholder="Megjegyzés (opcionális)" value={form.other} onChange={handleChange} />
                <div className='arconteneter'>
                    <div style={{ color: "black", fontSize: "20px" }}>Ár :</div>
                    <div style={{ color: "black", fontSize: "20px" }}>{price} Ft</div>
                </div>
                <StripePayment ref={stripeRef} />
                <div style={{ height: "10px" }} />
                </form>
            </div>
            )}
            {!purchaseComplete && (
            <button type="submit" className="submit-button" onClick={() => handleSubmit()}>Submit</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;