import React from "react";
import "../MainPagePCstyle.css";

const FooterPC = () => {
  const scrollToPosition = () => {
    window.scrollTo({
      top: 4750,
      behavior: 'smooth'
    });
  };

  const scrollToPosition2 = () => {
    window.scrollTo({
      top: 6100,
      behavior: 'smooth'
    });
  };
  const scrollToTermekeinkhead = () => {
    const element = document.getElementById('termekeinkhead');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  const scrollToMarqueecontainer = () => {
    const element = document.getElementById('marqueecontainer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToPosition3 = () => {
    window.scrollTo({
      top: 6650,
      behavior: 'smooth'
    });
  };
  return (
    <div class="footer">
    <h1 class="addictedfooterhead">ADDICTED</h1>
    <div class="footercontainer">
      <div class="kontaktbal">
        <div class="kontakttext">Kérdésed lenne a termékeinkkel kapcsolatban?<br/>Vedd fel velünk a kapcsolatot és mindre <br/>válaszolunk.</div>
        <div data-w-id="ad66c5ae-7166-7192-73f0-1c202fedd1dc" class="kontaktcont">
          <div class="kontaktgombtext">lÉPJ KAPCSOLATBA VELÜNK</div>
          <a href="mailto:mtamas@gmail.com" rel="noopener noreferrer" target="_blank"  class="kontaktnyilcont">
          <img src="images/nyil.png" loading="lazy" style={{webkitTransform:"translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(225deg) skew(0, 0)",mozTransform:"translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(225deg) skew(0, 0)",msTransform:"translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(225deg) skew(0, 0)",transform:"translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(225deg) skew(0, 0)"}} alt="" class="kontaktnyil"/>
          </a>
        </div>
      </div>
      <div class="footermid">
        <div class="menutext">[ Menü ]</div>
        <div class="menupontokcont">
          <div class="termekeinktext" onClick={()=>scrollToTermekeinkhead()}>Termékeink</div>
          <div class="kapcsolattext">Kapcsolat</div>
          <div class="rolunktext" onClick={()=>scrollToMarqueecontainer()}>Rólunk</div>
        </div>
      </div>
      <div class="footerjobb">
        <div class="text-block-9">[ információk ]</div>
        <div class="div-block-11">
          <div class="text-block-10">Nagykanizsa Csengery<br/>ÚT 10. FSZ.6</div>
          <div class="text-block-13">parkolás: fizetős</div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default FooterPC;