import React from "react";
import "../MainPagePCstyle.css";

const RolunkPC = () => {
  
  return (
         <div class="rolunkwrap">
      <div class="marqueecontainer" id="marqueecontainer">
        <div class="innercont">
          <h1 class="rolunkhead"><span class="white-border">Ismerj</span> <span class="black">meg minket</span></h1>
          <h1 class="rolunkhead"><span class="white-border">Ismerj</span> <span class="black">meg minket</span></h1>
        </div>
      </div>
      <div class="kepeksection">
        <div class="tomisection">
          <div class="imageholder">
          <img style={{transform:"translateY(-15%)"}} src="images/magyar-tamas-portre.webp" loading="lazy" sizes="100vw" srcset="images/magyar-tamas-portre-p-500.webp 500w, images/magyar-tamas-portre-p-800.webp 800w, images/magyar-tamas-portre-p-1080.webp 1080w, images/magyar-tamas-portre-p-1600.webp 1600w, images/magyar-tamas-portre-p-2000.webp 2000w, images/magyar-tamas-portre.webp 2552w" alt="" class="magyartomikep"/>
          </div>
          <div class="leirasholder">
            <h2 class="magyartomihead">Magyar Tamás</h2>
            <div class="textholders">
              <p class="paragraph">8 éves pályafutásom alatt számtalanszor megfogalmazódott bennem, hogy kéne egy olyan magyar termék a piacra, ami felgyorsítja a munkafolyamatokat, de mégsem megy a minőség rovására. Minél több toldófésűt használtam, annál inkább elvesztem a részletekben, ez folyamatos csúszást, idegeskedést, évekkel később betegséget is okozott számomra.<br/><br/>Aki maximalista, tudja, mi ez az érzés. Küldetésemnek érzem, hogy segítsem a hozzám hasonló típusú emberek életét.<br/><br/>Hosszas tesztelés és finomítás után társammal, Horváth Tomival (aki nélkül nem jöhetett volna létre), megalkottunk egy olyan kiegészítőt, ami széleskörű megoldást nyújt az alábbi problémákra.<br/><br/>Az Addicted Grain guard lényege, hogy nem egy hagyományos 3 millis fejet adunk, hanem egy olyan speciális toldófésűt, mely formájából adódóan képes 2-4 mm hosszt is vágni! Lényegében a gépi nyitott sávot és a zárt 4,5 millis hosszt feles talp felhasználása nélkül könnyedén összedolgozhatod másodpercek alatt, csupán a kar mozgatásával.Tapasztalatból mondom, a megfelelő technikával képes voltam leredukálni a 45 perces szolgáltatási időmet 30-35-re!</p>
              <div class="socialsection">
                <a href="https://www.instagram.com/mtamashair/" class="magyartomiinstalink w-inline-block">
                    <img src="images/logo-instagram-svgrepo-com.svg" loading="lazy" alt="" />
                </a>
                <a href="https://bwnet.hu/salon/1774/ADDICTED%20SALON%20&amp;%20BARBER/?t=services" class="bwlinkmagyartomi w-inline-block">
                <img src="images/bwlogo.svg" loading="lazy" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="htomisection">
          <div class="imageholder2">
          <img style={{transform:"translateY(-10%)"}} src="images/HORVTOMIkep.jpg" loading="lazy" sizes="100vw" srcset="images/HORVTOMIkep-p-500.jpg 500w, images/HORVTOMIkep-p-800.jpg 800w, images/HORVTOMIkep.jpg 1080w" alt="" class="horvattomikep"/>
          </div>
          <div class="leirasholder">
            <h2 class="horvtomihead">Horváth Tamás</h2>
            <div class="textholders">
              <p class="paragraph-2">Sziasztok!<br/>Pár éve már, hogy folyamatosan tevékenykedem többek között a 3D tervezés és nyomtatás terén is. Rengeteg kudarc és tapasztalat szerzés után mondhatom, hogy sikerült olyan szintre fejlődni ami lehetővé tette a közös munkát Magyar Tomival. <br/><br/>Régóta kapcsolatban állunk és rengetegszer agyaltunk azon mit lehetne kihozni a közös munkával. Sok elvetett és zseniális ötlet volt már amit vagy így vagy úgy, de a jövőben igyekszünk megvalósítani, hiszen célunk, hogy könnyebbé tegyük a munkátok.<br/><br/>Ezért a jövőben figyeljetek minket folyamatosan hiszen sosem tudhatjátok mivel rukkolunk elő!</p>
              <div class="socialsection">
                <a href="https://www.instagram.com/horv.tomi/" class="horvtomiinstalink w-inline-block">
                <img src="images/logo-instagram-svgrepo-com.svg" loading="lazy" alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolunkPC;