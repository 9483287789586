import React from "react";
import { useBase } from "../contexts/BaseContext";

const MessagePop = ({color}) => {
  const {pState,setPState,erM,form,amount,setPurchaseComplete,price} = useBase();
  const handleClick = () => {
    setPurchaseComplete(false);
    setPState(1);
  }
  const col = color === 'red' ? "Narancssárga" : color === 'blue' ? "Kék" : "Fehér"
  return (
    <div style={{display: "flex",flexDirection: "column"}}>
        {pState === 1 ? 
            <div className="purchase-summary">
                <h2>Köszönjük a vásárlást!</h2>
                <p><span>Mennyiség:</span> {amount}</p>
                <p><span>Szín:</span> {col}</p>
                <p><span>Név:</span> {form.name}</p>
                <p><span>Fizetett összeg:</span> {price} Ft</p>
                <p><span>Szállítási cím:</span> {form.address}</p>
            </div>
            :
            <>
            <div className="purchase-summary2">
                <h2>Hiba a vásárlás során!</h2>
                <p><span>Hiba:</span> {erM}</p>
            </div>
            <button className="submit-button" style={{width:"30%",alignSelf:"center"}} onClick={()=>handleClick()}>Újra</button>
            </>
        }
    </div>
  );
};

export default MessagePop;