import React from "react";
import "../MainPagePCstyle.css";
import { useBase } from "../../../contexts/BaseContext";

const TermekPc = () => {
  const {openPopUp} = useBase();
  return (
        <div class="termekeinkwrap">
      <h1 class="termekeinkhead" id="termekeinkhead">Termékeink</h1>
      <div class="round-loope"></div>
      <div data-w-id="524b13e0-9cde-24f1-1cc9-c6b009b97e6b" class="termekcardwrap">
        <div class="termekcont" onClick={()=>openPopUp()}>
            <div class="termekcard">
                <div class="div-block-3">
                    <img src="images/IMG_5D92E218F3CD-1.jpeg" loading="lazy" sizes="(max-width: 479px) 248px, (max-width: 767px) 40vw, (max-width: 991px) 30vw, 20vw" srcset="images/IMG_5D92E218F3CD-1-p-500.jpeg 500w, images/IMG_5D92E218F3CD-1-p-800.jpeg 800w, images/IMG_5D92E218F3CD-1-p-1080.jpeg 1080w, images/IMG_5D92E218F3CD-1.jpeg 1170w" alt="" class="termekkep"/>
                </div>
                <div class="termekleiras">
                    <div class="addictedtext">ADDICTED</div>
                    <h3 class="tolofesuhead">2-4 mm toldófésű</h3>
                    <div class="mtomitext">by Magyar Tamás</div>
                </div>
                <button class="vasarlas-button">Vásárlás</button>
            </div>
        </div>
    </div>
      <div style={{display:"none",opacity:"1"}} class="popup-wrap">
        <div class="popup">
          <div class="bezaro">
            <div data-w-id="dcd50bb0-cd53-afce-4a7a-c48276b3d32d" class="bezaroholder">
              <div data-w-id="dcd50bb0-cd53-afce-4a7a-c48276b3d32e" class="ms2-svg w-embed"><svg xmlns="http://www.w3.org/2000/svg" height="auto" viewbox="0 0 24 24" width="1000px" fill="currentColor">
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg></div>
            </div>
          </div>
          <div class="slatt">
            <div class="kepholder">
            <img src="images/MG_4933-1.jpeg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 85vw, 100vw" srcset="images/MG_4933-1-p-500.jpeg 500w, images/MG_4933-1-p-800.jpeg 800w, images/MG_4933-1-p-1080.jpeg 1080w, images/MG_4933-1-p-1600.jpeg 1600w, images/MG_4933-1.jpeg 1622w" alt="" class="fotermekkep" />
            </div>
            <div class="leiras">
              <h3 class="heading-6">ADDICTED 2-4 mm toldófésű<br/></h3>
              <div class="text-block-5">A termékeink 3D nyomtatással biológiai úton lebomló anyagból készülnek. Ez az anyag tökéletesen megfelel a toldófésűk anyagának, kissé rugalmas és rengeteg tesztelés után mondható, hogy a nagy mértékű rezgésnek is ellenáll. Viszont mivel lebomló anyagról beszélünk nem ajánlatos vegyszerben áztatni mivel egy idő után feloldódhat benne.</div>
              <div class="text-block-6">Kérünk titeket fokozottan figyeljetek erre és ha szükséges inkább csak fújjátok le fertőtlenítővel és töröljétek át. De a legcélszerűbb ha fújás után letörlitek a fertőtlenítő nagyját és hideg levegővel átfújhatjátok. Még egy fontos dolog amire fokozottan figyeljetek 55 °C-fok körül már lágyul az anyag így tartózkodjatok a magas hőtől való érintkezéstől.<br/></div>
              <div class="text-block-7">7990 Ft.</div>
              <div class="szinvalaszto">
                <div class="text-block-8">SZÍN</div>
                <div class="div-block-5">
                  <div class="szin1">
                    <div class="div-block-6"></div>
                  </div>
                  <div class="szin2">
                    <div class="div-block-7"></div>
                  </div>
                  <div class="szin3">
                    <div class="div-block-8"></div>
                  </div>
                </div>
              </div>
              <div>
                <a href="#" class="button w-button">Vásárlás</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="images/tominoveny1.png" loading="lazy" sizes="100vw" srcset="images/tominoveny1-p-500.png 500w, images/tominoveny1-p-800.png 800w, images/tominoveny1.png 962w" alt="" class="leveljobb"/>
      <img src="images/tomivirag2v3.png" loading="lazy" sizes="100vw" srcset="images/tomivirag2v3-p-500.png 500w, images/tomivirag2v3-p-800.png 800w, images/tomivirag2v3-p-1080.png 1080w, images/tomivirag2v3.png 1094w" alt="" class="levelbal"/>
    </div>
  );
};

export default TermekPc;