import React, { useState } from "react";
import "../MainPagePCstyle.css";

const HeroPc = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToPosition = () => {
    window.scrollTo({
      top: 3950,
      behavior: 'smooth'
    });
    setMenuOpen(false);
  };

  const scrollToPosition2 = () => {
    window.scrollTo({
      top: 5950,
      behavior: 'smooth'
    });
    setMenuOpen(false);
  };
  const scrollToTermekeinkhead = () => {
    const element = document.getElementById('termekeinkhead');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  const scrollToMarqueecontainer = () => {
    const element = document.getElementById('marqueecontainer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToPosition3 = () => {
    window.scrollTo({
      top: 10000,
      behavior: 'smooth'
    });
    setMenuOpen(false);
  };

  return (
    <div className="section is--hero">
      <div className="telotabletnav">
        <a href="#" className="w-inline-block">
          <img src="images/tomilogo.png" loading="lazy" sizes="(max-width: 991px) 150px, 100vw" srcSet="images/tomilogo-p-500.png 500w, images/tomilogo-p-800.png 800w, images/tomilogo-p-1080.png 1080w, images/tomilogo.png 1574w" alt="" className="image" />
        </a>
        <div data-w-id="499bbf6e-58a7-1165-1d1a-c2b3fc50e338" className="hambicont-copy-3" onClick={toggleMenu}>
          <div className="tophambi-2"></div>
          <div className="midhambi-2"></div>
          <div className="bothambi-2"></div>
        </div>
      </div>
      {menuOpen && (
        <div className="menu">
          <a className="nav_link2 w-inline-block" onClick={scrollToMarqueecontainer}>
            <p className="nav_link-text2">RÓLUNK</p>
          </a>
          <a className="nav_link2 w-inline-block" onClick={scrollToTermekeinkhead}>
            <p className="nav_link-text2">TERMÉKEK</p>
          </a>
          <a className="nav_link2 w-inline-block" onClick={scrollToPosition3}>
            <p className="nav_link-text2">KAPCSOLAT</p>
          </a>
        </div>
      )}
    </div>
  );
};

export default HeroPc;