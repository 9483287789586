// AdminPage.js
import React from 'react';
import { useAdmin } from './AdminContext';
import './Admin.css';
import { useBase } from '../contexts/BaseContext';
import AdminList from './AdminList';
import AdminKeszlet from './AdminKeszlet';

const AdminPage = () => {
    const { logout,pageAdmin,setPageAdmin,napstat,name,setName,
            name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
            cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
            setNapStat,getToggles,hnum,setHnum,toggles,getColor,handleToggle,handleSubmitAdmin
    } = useAdmin();

    const {getDatesAdmin,selectedRangeAdmin, setSelectedRangeAdmin,selectedRange2Admin, setSelectedRange2Admin,selectedRange3Admin, setSelectedRange3Admin,clearRangesAdmin} = useBase();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; 
    const handleFoglalasClickAdmin = () => {
        if (napstat === 0)  {
            getToggles(selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin)
            setNapStat(1);
        }
        if (napstat === 1)  {
            if (name !== "" && name !== null) {
                handleSubmitAdmin(selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin);
                setNapStat(0);
                clearRangesAdmin();
            }
        }
    }
    const handleVisszaClickAdmin = () => {
        setNapStat(0);
    }

    return (
        <div className="admin-container">
            <nav className="admin-navbar">
                <ul>
                    <li onClick={()=>setPageAdmin('nap')}>Készlet</li>
                    <li onClick={()=>setPageAdmin('list')}>Rendelés Lista</li>
                </ul>
                <button onClick={logout} className="logout-button">Logout</button>
            </nav>
            <div>
                {pageAdmin === "nap" && 
                    <AdminKeszlet/>
                }
                {pageAdmin === "list" &&
                    <AdminList/>
                }
                {pageAdmin === "stat" &&
                    <div></div>
                }
            </div>
        </div>
    );
};

export default AdminPage;