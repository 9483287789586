import React from "react";
import "../MainPagePCstyle.css";

const License = () => {
  
  return (
    <div class="licensz">
      <div class="text-block-12">2024. GMA CORPORATION. MINDEN JOG FENNTARTVA </div>
    </div>
  );
};

export default License;